<template>
  <div class="admin-assign-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Assign Devices</h2></b-card-header>
        <b-card-body>
          <div>
            <div>
              <h3>Email:</h3>
              <b-input-group>
                <b-input placeholder="Email Address" v-model="targetEmail"></b-input>
                <!--              <b-input-group-append>-->
                <!--                <b-button @click="findUser">Find</b-button>-->
                <!--              </b-input-group-append>-->
              </b-input-group>

            </div>
            <admin-barcode-input hide-email ref="deviceInput"></admin-barcode-input>
            <b-button class="mb-2" @click="assignDevices">Assign Devices</b-button>
          </div>
          <div v-if="results">
            <h3>Results:</h3>
            <div v-for="(result, idx) of results" :key="idx">
              {{result.text}}
              <i class="icon-button row-icon-button" :class="$config.icons.general.success"
                 v-b-tooltip title="Success" v-if="result.success"></i>
              <i class="icon-button row-icon-button-danger" :class="$config.icons.general.failure"
                 v-b-tooltip title="Failure" v-if="!result.success"></i>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>

import AdminBarcodeInput from '@/components/admin/AdminBarcodeInput'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'
export default {
  name: 'admin-assign-devices',
  components: {
    AdminBarcodeInput
  },
  data: function () {
    return {
      targetEmail: '',
      results: null
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    validateEmail: async function () {
      let res = await DataProvider.isValidUser(this.targetEmail)
      if (res.success) {
        console.log(res)
        return res.data.user_exists
      } else {
        ErrorHelper.displayDataErrorToast(res)
        return false
      }
    },
    assignDevices: async function () {
      if (!await this.validateEmail()) {
        ErrorHelper.displayGeneralErrorToast('Invalid Email Address', 'Invalid Email')
        return
      }
      if (!this.$refs.deviceInput.validate()) {
        ErrorHelper.displayGeneralErrorToast('Check your input fields, one or more show errors.', 'Errors')
        return
      }
      let devices = this.$refs.deviceInput.devices.filter(x => x.device_imei !== '')
      // Grab the list of IMEIs, deal with retries by checking for duplicates
      let results = []
      for (let device of devices) {
        if (device.imei === '') { // Ignore blanks
          continue
        }
        let result = await DataProvider.adminAssignDevice(device.device_imei, this.targetEmail)
        if (result.success) {
          AlertHelper.successToast(
            `Assigned device ${result.data.device} ot ${result.data.user}`,
            'Device Assignment Successful'
          )
          results.push({ text: 'Assigned: ' + result.data.device + ' to ' + result.data.user, success: true })
        } else {
          ErrorHelper.displayDataErrorToast(result)
          console.log('Failed to assign: ', result)
          if (result.error.message) {
            results.push({ text: 'Failed: ' + device.device_imei + ': ' + result.error.message, success: false })
          } else {
            results.push({ text: 'Failed to Assign: ' + device.device_imei, success: false })
          }
        }
      }
      this.results = results
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.admin-assign-devices {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}

.list-containter {
  background: $theme-color-background-4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}

</style>
